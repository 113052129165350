import {Link} from "gatsby"
import React from "react"

interface Props {
  newsType: "overig" | "PGS"
}

const NewsOverviewLink = ({newsType}: Props) => {
  return (
    <Link className="morenews" to={`/${newsType.toLowerCase()}-nieuws`}>
      Bekijk compleet {newsType} nieuws archief
    </Link>
  )
}

export default NewsOverviewLink
