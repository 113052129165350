import {Link} from "gatsby"
import React from "react"
import slugify from "../../functions/slugify"
import {NewsNode} from "../../interfaces/news"

interface Props {
  newsList: NewsNode[]
  className?: "dashed" | "dotted"
}

const NewsList = ({newsList, className}: Props) => {
  return (
    <ul className={`news other ${className}`}>
      {newsList.map((news, index: number) => (
        <div key={index}>
          <li>
            <span className="date">{news.date}</span>
            <Link to={"/nieuws/" + slugify(news.title)}>{news.title}</Link>
          </li>
        </div>
      ))}
    </ul>
  )
}

export default NewsList
