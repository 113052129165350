import React from "react"
import {graphql, HeadFC} from "gatsby"
import Layout from "../components/layout"
import parse from "html-react-parser"
import NewsList from "../components/news/news-list"
import {NewsNode, PublicationType} from "../interfaces/news"
import NewsOverviewLink from "../components/news/news-overview-link"
import {SEO} from "../components/seo"

interface NewsQuery {
  datoCmsNews: {title: string}
  data: {
    datoCmsNews: {
      title: string
      introduction: string
      body: string
      date: string
      image: {
        url: string
        title: string
        alt: string
      }
    }
    allDatoCmsNews: {
      nodes: NewsNode[]
    }
  }
  pageContext: {
    id: string
    url: string
    publicationType: PublicationType
  }
}

const NewsTemplate = ({data, pageContext}: NewsQuery) => {
  const {title, body, introduction, image, date} = data.datoCmsNews
  const allNews = data.allDatoCmsNews.nodes
  const caption = image?.title

  const formattedDate = new Date(date).toLocaleString("en-NL", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })

  const newsType =
    pageContext.publicationType === "publicatie" ? "PGS" : "overig"

  return (
    <Layout currentLocation={title}>
      <h1>{title}</h1>
      <p>
        <em>{formattedDate}</em>
      </p>
      {image && (
        <div className="figure">
          <div className="image">
            <img src={image.url} alt={caption} />
          </div>
          <div className="caption">{caption}</div>
        </div>
      )}
      <strong>{parse(introduction)}</strong>
      <br />
      <br />
      {parse(body)}
      <NewsList className="dashed" newsList={allNews} />
      <NewsOverviewLink newsType={newsType} />
    </Layout>
  )
}

export const query = graphql`
  query NewsQuery($id: String, $publicationType: String) {
    datoCmsNews(id: {eq: $id}) {
      title
      introduction
      date
      body
      image {
        url
        title
        alt
      }
    }
    allDatoCmsNews(
      filter: {publicationType: {eq: $publicationType}}
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        publicationType
        title
        introduction
        date(formatString: "DD MMM YYYY")
      }
    }
  }
`

export default NewsTemplate

export const Head = ({data}: NewsQuery) => {
  return <SEO title={data.datoCmsNews.title} />
}
